import { IApi } from './types';
import { auth } from './auth';
import { settings } from './settings';
import { twoFa } from './twoFa';
import { confirmation } from './confirmation';
import { registrations } from './registrations';
import { transactions } from './transactions';
import { wallets } from './wallets';
import { deposits } from './deposits';
import { currency } from './currency';
import { walletAddresses } from './walletAddresses';
import { bankAccounts } from './bankAccounts';
import { bonusSystem } from './bonusSystem';
import { download } from './download';
import { referrals } from './referrals';
import { detailedView } from './detailedView';
import { messages } from './messages';

const api: IApi = {
	registrations,
	auth,
	settings,
	twoFa,
	confirmation,
	transactions,
	wallets,
	deposits,
	currency,
	walletAddresses,
	bankAccounts,
	bonusSystem,
	download,
	referrals,
	detailedView,
	messages,
};

export { api };
