import React, { useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { ICurrencyDataItem } from 'redux/reducers/currency/types';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { ILimits } from 'services/api/transactions/types';
import { roundingNumber } from 'services/utils/roundingNumber';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

export interface ITradeInput {
	swap: boolean;
	inputValue: string;
	inputError: boolean;
	selectValue: string;
	selectValueOtherElement: string;
	setInputError: (value: boolean) => void;
	onInputChange?: (value: string, withError: boolean) => void;
	onSelectChange: (value: string, id: number) => void;
	currency?: ICurrencyDataItem[];
	allCurrency?: ICurrencyDataItem[];
	wallets?: IWalletItem[] | null;
	limits?: ILimits | null;
	setType: (value: string) => void;
	title: string;
	readOnly?: boolean;
}

const TradeInput: React.FC<ITradeInput> = ({
	swap,
	inputValue,
	selectValue,
	selectValueOtherElement,
	inputError,
	onInputChange,
	onSelectChange,
	setInputError,
	currency,
	wallets,
	limits,
	setType,
	allCurrency,
	title,
	readOnly,
}) => {
	const selectRef = useRef<HTMLDivElement | null>(null);
	const selectDropRef = useRef<HTMLDivElement | null>(null);
	const [selectList, setSelectList] = useState(currency);

	const [withError, setWithError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [selectOpen, setSelectOpen] = useState(false);
	const [availableBalance, setAvailableBalance] = useState('0');
	const [filteredSelectValue, setFilteredSelectValue] = useState<ICurrencyDataItem | undefined>(
		undefined,
	);
	const [searchInputValue, setSearchInputValue] = useState('');
	const [activeCurrTab, setActiveCurrTab] = useState(
		currency?.find((item) => item.code === selectValue)?.type,
	);
	const [activeCurrTabChecked, setActiveCurrTabChecked] = useState<null | 'crypto' | 'fiat'>(null);
	useEffect(() => {
		setActiveCurrTabChecked(null);
	}, [swap]);
	useEffect(() => {
		const result = currency?.find((item) => item.code === selectValue)?.type;
		if (activeCurrTabChecked === null && (result === 'crypto' || result === 'fiat')) {
			setActiveCurrTab(result);
			setActiveCurrTabChecked(result);
		}
	}, [currency, selectValue, activeCurrTabChecked]);
	const maxValue =
		!!limits && limits.limit_max < Number(availableBalance)
			? limits.limit_max
			: Number(availableBalance);

	const getAvailableBalance = useMemo(
		() => (value: string) => {
			const foundAvailableBalance = wallets?.find((el) => el.asset.code === value);
			const validateAvailableBalance =
				// foundAvailableBalance && Number(foundAvailableBalance?.balance) > 0.001
				foundAvailableBalance ? foundAvailableBalance.balance : '0';
			setAvailableBalance(validateAvailableBalance);
			return validateAvailableBalance;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectValue, wallets],
	);

	const inputValidate = (value: string) => {
		// const numberValue = Number(value);
		// const validateZero = Number.isNaN(numberValue) ? true : numberValue > 0;
		const validateLimitMin = limits ? limits?.limit_min <= Number(value.replace(/,/g, '')) : true;
		const validateLimitMax = limits
			? Number(toFixedNumber(maxValue, selectValue).replace(/,/g, '')) >=
			  Number(value.replace(/,/g, ''))
			: true;
		// !validateZero && setErrorMessage('Value must be greater than 0');

		!validateLimitMin &&
			setErrorMessage(`Min Amount is ${toFixedNumber(limits?.limit_min, selectValue) || 0}`);
		!validateLimitMax &&
			setErrorMessage(
				`Max Amount is ${
					toFixedNumber(maxValue, selectValue) || 1
				}. If you want to increase your trade limit, please <a href="mailto:support@kaiserex.com">contact support</a>`,
			);
		const validate = !(validateLimitMin && validateLimitMax);
		setWithError(validate);
		return validate;
	};

	const chooseAllAvailableBalance = () => {
		const newValue = toFixedNumber(availableBalance, selectValue).replace(/,/g, '');
		const validate = inputValidate(newValue);
		onInputChange && onInputChange(newValue, validate);
	};

	const searchInSelect = (val: string) => {
		const filteredSelectList = currency
			?.filter((el) => el.code !== selectValue && el.code !== selectValueOtherElement)
			?.filter(({ code }) => code.includes(val.toLowerCase()));
		setSelectList(filteredSelectList);
		setSearchInputValue(val);
	};

	const changeSearchTabHandler = (type: string) => {
		setActiveCurrTab(type);
	};

	const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const result = e.target.value.replace(/[^\d.]/g, '').replace(/^([^.]*\.)|\./g, '$1');
		const validate = inputValidate(result);
		onInputChange && onInputChange(result, validate);
	};

	const selectChangeHandler = (value: string, id: number, currencyType: string) => {
		setSelectOpen(false);
		!!onSelectChange && onSelectChange(value, id);
		setType(currencyType);
	};
	const formatValue = inputValue.includes('.')
		? inputValue.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
		: Number(inputValue).toLocaleString('en-US');

	const filteredSelectValueCode = filteredSelectValue?.code;
	const [checkedCode, setCheckedCode] = useState<any>('');
	useEffect(() => {
		if (currency) {
			const selectedCode = currency?.find((asset) => asset.code === filteredSelectValueCode)
				? filteredSelectValueCode
				: currency[0]?.code;
			setCheckedCode(selectedCode);
		}
	}, [currency, filteredSelectValueCode]);

	useEffect(() => {
		setSelectList(
			currency?.filter((el) => el.code !== selectValue && el.code !== selectValueOtherElement),
		);
	}, [currency, selectValueOtherElement, selectValue]);
	/* useEffect(() => {
		console.log(selectList);
	}, [selectList]); */
	useEffect(() => {
		if (wallets) {
			getAvailableBalance(selectValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectValue, wallets]);

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				!selectRef.current?.contains(event.target as Node) &&
				!selectDropRef.current?.contains(event.target as Node)
			) {
				setSelectOpen(false);
			}
		};

		if (selectOpen) {
			document.addEventListener('mousedown', handleOutsideClick);
		}
		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [selectOpen]);

	useEffect(() => {
		if (inputError || inputValue) {
			const validate = inputValidate(inputValue);
			setInputError(validate);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue, selectValue, availableBalance, limits]);

	useEffect(() => {
		const foundCurrency = allCurrency?.find(
			(el) => el.code.toLowerCase() === selectValue.toLowerCase(),
		);

		if (!foundCurrency) {
			const isInAllCurrency = allCurrency?.some((el) => el.code === checkedCode);

			if (isInAllCurrency) {
				if (allCurrency) {
					setFilteredSelectValue(allCurrency[0]);
					searchInSelect(String(allCurrency[0].code));
				}
			} else {
				setFilteredSelectValue(checkedCode);
			}
		} else {
			setFilteredSelectValue(foundCurrency);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectValue, allCurrency]);

	return (
		<>
			{!!wallets && title !== 'You Receive' && (
				<div className="instant-trade-form__balance">
					<p>Available Balance:</p>
					<span>
						{toFixedNumber(availableBalance, selectValue, true)} {selectValue}
					</span>
				</div>
			)}
			<div className="form instant-trade-form-item__field instant-trade-form-item__field--exchange">
				<AnimatePresence>
					{selectOpen && (
						<motion.div
							ref={selectDropRef}
							className="select__drop select__drop--top active"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { duration: 0.3 } }}
							exit={{ opacity: 0, transition: { duration: 0.3 } }}
						>
							<div className="select__tabs">
								<button
									type="button"
									className={`select__tab ${activeCurrTab === 'fiat' ? 'active' : ''}`}
									onClick={() => changeSearchTabHandler('fiat')}
								>
									Fiat
								</button>
								<button
									type="button"
									className={`select__tab ${activeCurrTab === 'crypto' ? 'active' : ''}`}
									onClick={() => changeSearchTabHandler('crypto')}
								>
									Crypto
								</button>
							</div>
							<div className="input input--search">
								<div className="input-wrapper">
									<input
										className="input-item"
										type="text"
										placeholder="Select Currency"
										onChange={(e) => {
											searchInSelect(e.target.value);
										}}
										value={searchInputValue}
									/>
									<div className="input-icon input-icon--search" />
									<div
										className="input-icon input-icon--clear"
										onClick={() => {
											setSearchInputValue('');
											searchInSelect('');
										}}
									/>
								</div>
							</div>
							<div className="select__drop-scroll">
								<div className="select__drop-item">
									<AnimatePresence>
										{activeCurrTab === 'fiat' && (
											<motion.ul
												initial={{ opacity: 0 }}
												animate={{ opacity: 1, transition: { duration: 0.5 } }}
												exit={{ opacity: 0, transition: { duration: 0 } }}
											>
												{!!selectList?.length &&
													selectList
														.filter(({ type }) => type === 'fiat')
														.map(({ id, code, type, img_path, name }) => {
															return (
																<li key={id}>
																	<button
																		className="element---uppercase"
																		onClick={() => selectChangeHandler(code, id, type)}
																		type="button"
																	>
																		<span className="coin coin--type3 coin__text-normal">
																			{img_path && (
																				<span className="coin__icon">
																					<img
																						src={`/img/currencies/${code.toLowerCase()}.svg`}
																						alt={name}
																					/>
																				</span>
																			)}
																			<span className="coin__text coin__text---pt-0 text-transform-none">
																				{name}{' '}
																				<span className="coin__text-more text-transform-uppercase coin__text-more--type2">
																					{code}
																				</span>
																			</span>
																		</span>
																	</button>
																</li>
															);
														})}
											</motion.ul>
										)}
									</AnimatePresence>
									<AnimatePresence>
										{activeCurrTab === 'crypto' && (
											<motion.ul
												initial={{ opacity: 0 }}
												animate={{ opacity: 1, transition: { duration: 0.5 } }}
												exit={{ opacity: 0, transition: { duration: 0 } }}
											>
												{!!selectList?.length &&
													selectList
														.filter(({ type }) => type !== 'fiat')
														.map(({ id, code, type, img_path, name }) => {
															return (
																<li key={id}>
																	<button
																		className="element---uppercase"
																		onClick={() => selectChangeHandler(code, id, type)}
																		type="button"
																	>
																		<span className="coin coin--type3 coin__text-normal">
																			{img_path && (
																				<span className="coin__icon">
																					<img
																						src={`/img/currencies/${code.toLowerCase()}.svg`}
																						alt={name}
																					/>
																				</span>
																			)}
																			<span className="coin__text text-transform-none coin__text---pt-0">
																				{name}{' '}
																				<span className="coin__text-more text-transform-uppercase coin__text-more--type2">
																					{code}
																				</span>
																			</span>
																		</span>
																	</button>
																</li>
															);
														})}
											</motion.ul>
										)}
									</AnimatePresence>
								</div>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
				<div className="input">
					<div className={`input-wrapper ${withError ? 'input--error' : ''}`}>
						<div className="instant-trade-form-item__header">
							<p>{title}</p>
							{!!wallets && (
								<div className="instant-trade-form-item__max">
									<button
										type="button"
										className="input-button"
										onClick={chooseAllAvailableBalance}
									>
										Max
									</button>
								</div>
							)}
						</div>
						<input
							type="text"
							placeholder={
								limits
									? `${toFixedNumber(limits?.limit_min, selectValue)} - ${toFixedNumber(
											maxValue,
											selectValue,
									  )}`
									: '0.01'
							}
							value={formatValue}
							// placeholder="0"
							// value={inputValue}
							onChange={inputChangeHandler}
							readOnly={readOnly}
						/>
					</div>
					{withError && (
						<div className="input-notify">
							<span
								className="input-notify__text"
								dangerouslySetInnerHTML={{ __html: errorMessage }}
							/>
						</div>
					)}
				</div>
				<div className="select-block">
					<div className={`select ${selectOpen ? 'active' : ''}`} ref={selectRef}>
						<button
							type="button"
							className="select__current"
							onClick={() => {
								setSelectOpen(!selectOpen);
							}}
						>
							<span className="coin">
								{filteredSelectValue?.img_path && (
									<span className="coin__icon">
										<img
											src={`/img/currencies/${selectValue.toLowerCase()}.svg`}
											alt={selectValue}
										/>
									</span>
								)}
								<span className="coin__text">{checkedCode || selectValue}</span>
							</span>
							<span className="select__current-arrow" />
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default TradeInput;
