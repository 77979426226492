import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessagesRequest } from 'redux/reducers/messages/reducer';
import { getMessages } from 'redux/reducers/messages/selectors';
import Pagination from 'components/Pagination/Pagination';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';

import Accordion from './Accordion/Accordion';

const dummyMessages = [
	{
		id: 341,
		title: 'planned_on 12:03 UTC',
		message: '<p>\ud83d\udc7b</p>',
		users_all: 1,
		sent: 1,
		planned_on: '2024-12-24 12:03:00',
		created_at: '2024-12-24T12:01:49.000000Z',
		updated_at: '2024-12-24T14:03:01.000000Z',
		view: 1,
		files: [],
	},
	{
		id: 340,
		title: 'planned_on 12:30 UTC',
		message: '<p><em>message test</em></p>',
		users_all: 1,
		sent: 1,
		planned_on: '2024-12-12 12:30:00',
		created_at: '2024-12-12T12:24:22.000000Z',
		updated_at: '2024-12-12T14:25:02.000000Z',
		view: 0,
		files: [],
	},
	{
		id: 339,
		title: 'But I must explain to you how all this mistaken idea',
		message:
			'<p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the s</p>',
		users_all: 1,
		sent: 1,
		planned_on: '2024-12-12 14:30:00',
		created_at: '2024-12-12T12:13:16.000000Z',
		updated_at: '2024-12-12T14:30:02.000000Z',
		view: 1,
		files: ['6WtoZAVvUZ/utilitybilltest.pdf', 'QdUtVpFZod/utilitybilltest.pdf'],
	},
];

const InformationTab = () => {
	const dispatch = useDispatch();
	const messages = useSelector(getMessages);

	const [perPage, setPerPage] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState(1);

	const pageCount = messages?.last_page ? messages?.last_page : 1;

	const handleSetPerPage = (value: number | undefined) => {
		if (value) {
			setPerPage(value);
		} else {
			setPerPage(9999);
		}
		setCurrentPage(1);
	};

	// useEffect(() => {
	// 	dispatch(getMessagesRequest({ current_page: currentPage, per_page: perPage }));
	// }, [currentPage, perPage, dispatch]);

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	return (
		<div>
			<div className="wallet-content-header wallet-content-header--mb-40">
				<div className="wallet-content-header__title">
					<p>Information</p>
				</div>
			</div>
			<div className="accordion accordion--mt-0">
				{/* {messages?.data && messages.data.length
						? messages.data.map((message) => <Accordion key={message.id} message={message} />)
						: 'No data'} */}
				{dummyMessages.length
					? dummyMessages.map((message) => <Accordion key={message.id} message={message} />)
					: 'No data'}
			</div>
			{messages?.data && messages?.data.length ? (
				<div className="table-footer">
					<div className="select-page">
						<PerPageSelect onChange={handleSetPerPage} />
					</div>

					{pageCount > 1 && (
						<div className="pagination-block">
							<Pagination
								pageCount={pageCount}
								forcePage={currentPage}
								onPageChange={handlePageClick}
							/>
						</div>
					)}
				</div>
			) : null}
		</div>
	);
};

export default InformationTab;
